<template>
  <div class="row">
    <loading-notification :show="loading" />
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('themes.view')">
        <actions
          slot="actions"
          crud-links="themes"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <text-list :title="$t('tables.headings.name')">
          {{ theme.name }}
        </text-list>
        <text-list :title="$t('tables.headings.status')">
          <va-badge
            class="mb-2"
            :color="theme.active ? 'success' : 'danger'"
          >{{ $t(themeActive) }}</va-badge>
        </text-list>
        <text-list
          :title="$t('themes.inputs.primary_tint')"
          hasAvatar
        >
          <template slot="avatar">
            <span
              class="circle"
              :style="{'color': primaryColor}"
            ></span>
          </template>
          {{ theme.primary_color }}
        </text-list>
        <text-list
          :title="$t('themes.inputs.green_tint')"
          hasAvatar
        >
          <template slot="avatar">
            <span
              class="circle"
              :style="{'color': successColor}"
            ></span>
          </template>
          {{ theme.success_color }}
        </text-list>
        <text-list
          :title="$t('themes.inputs.red_tint')"
          hasAvatar
        >
          <template slot="avatar">
            <span
              class="circle"
              :style="{'color': dangerColor}"
            ></span>
          </template>
          {{ theme.danger_color }}
        </text-list>
        <text-list
          :title="$t('themes.inputs.blue_tint')"
          hasAvatar
        >
          <template slot="avatar">
            <span
              class="circle"
              :style="{'color': infoColor}"
            ></span>
          </template>
          {{ theme.info_color }}
        </text-list>
      </va-card>
    </div>
    <div class="flex xs12 sm4">
      <va-card :title="$t('themes.inputs.navbarImageInput')">
        <div
          class="img-container img-container__navbar"
          :style="{'background-image': `url('${navbarImage}')`}"
        ></div>
      </va-card>
    </div>
    <div class="flex xs12 sm4">
      <va-card :title="$t('themes.inputs.loginImageInput')">
        <div
          class="img-container img-container__login"
          :style="{'background-image': `url('${loginImage}'), ${gradient}`}"
        ></div>
      </va-card>
    </div>
    <div class="flex xs12 sm4">
      <va-card :title="$t('themes.inputs.backgroundImageInput')">
        <div
          class="img-container img-container__background"
          :style="{'background-image': `url('${backgroundImage}')`}"
        ></div>
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'themes-view',
  components: {
    Actions, TextList,
  },
  data () {
    return {
      error: false,
      theme: {},
      loading: false,
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    themeActive () {
      return this.theme.active ? 'tables.status.active' : 'tables.status.inactive'
    },
    primaryColor () {
      return this.theme.primary_color || 'black'
    },
    successColor () {
      return this.theme.success_color || 'black'
    },
    dangerColor () {
      return this.theme.danger_color || 'black'
    },
    infoColor () {
      return this.theme.info_color || 'black'
    },
    navbarImage () {
      return this.getBaseSrc() + (this.theme.navbar_image || '/img/loading.gif')
    },
    loginImage () {
      return this.getBaseSrc() + (this.theme.login_image || '/img/loading.gif')
    },
    backgroundImage () {
      return this.getBaseSrc() + (this.theme.background_image || '/img/loading.gif')
    },
    gradient () {
      return `
        linear-gradient(
          to top,
          var(--brand-lighterGray),
          var(--brand-primary)
        )
      `
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    getBaseSrc () {
      let src = ''
      if (process.env.NODE_ENV === 'development') {
        const base = this.$http.defaults.baseURL.match(/^https?:\/\/[^/]+/g)[0]
        src = base
      }
      return src
    },
    routeBuilder (id) {
      return `themes/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const themeId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(themeId))
      } catch (err) {
        // console.log('Error fetching theme data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.theme = u.data.data
    },
  },
}
</script>

<style lang="scss" scoped>
.img-container {
  width: 100%;
  height: auto;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &__navbar {
    background-color: var(--brand-primary);
  }

  &__login {
    background-color: linear-gradient(
      to top,
      var(--brand-lighterGray),
      var(--brand-primary)
    );
  }
}

.circle::before {
  content: " \25CF";
  font-size: 20px;
}
</style>
